<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Crédito | Extrato</h2>
      </div>
    </div>
      <div class="section-content">
        <div class="list-container">
          <div class="vld-parent">
            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="fullPage"
            ></loading>
          </div>
          <div class="header-container shadow-sm p-3 mb-5 bg-white rounded">
                <b-form-radio-group
                  v-model="selected"
                  class="search-filter"
                  button-variant="outline-primary"
                  buttons
                >
                  <b-form-radio value="user" v-b-tooltip.hover title="Usuário Responsável" >Usuário</b-form-radio>
                  <b-form-radio value="created" v-b-tooltip.hover title="Formato aceito: 15(dia), 2020, 15/01/2020">Data</b-form-radio>
                  <b-form-radio value="transactionType" v-b-tooltip.hover title="Tipo da transação">Transação</b-form-radio>
                  <b-form-radio value="mediaLenght" v-b-tooltip.hover title="Tempo da gravação">Tempo</b-form-radio>
                  <b-form-radio value="observation" v-b-tooltip.hover title="Observação">Observação</b-form-radio>
                </b-form-radio-group>
            <div class="search-container">
              <b-input-group size="sm" class="mb-0">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input v-model="filterText" type="search" placeholder="Buscar..." v-on:keyup.enter="onSearchClick()"></b-form-input>
              </b-input-group>
            </div>
          </div>
           <b-table class="shadow-sm bg-white rounded" :fields="fields" :items="extracts" :per-page="0" :current-page="currentPage" @sort-changed="sortChanged" stacked="md">
             <template v-slot:cell(api)="row">
               <img :src="getApiIconStyle(row.item)">
             </template>
          </b-table>
          <div class="pagination-container shadow-sm bg-white rounded">
            <b-row>
              <b-col class="my-3">
                <b-pagination
                        @input ="updatePage()"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                ></b-pagination>
              </b-col>
              <b-col  class="my-3">
                <b-form-select
                        @input ="updatePage()"
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                ></b-form-select>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
  import { storeService } from '../../services/store.service';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import searchIcon from '@/assets/icons/magnify.svg';
  import doneIcon from '@/assets/icons/done.svg';
  import moment from 'moment';

  export default {
    components: {
      Loading
    },
    data () {
      return {
        searchIcon,
        doneIcon,
        extracts: [],
        filterText: '',
        isLoading: false,
        fullPage: true,
        fields: [
          { key: 'login', label: 'Usuário', sortable: true },
          {
            key: 'created',
            label: 'Data',
            sortable: true,
            sortDirection: 'desc',
            formatter: (value, key, item) => {
              return moment.unix(value).format('DD/MM/YYYY HH:mm');
            }
          },
          {
            key: 'transactionType',
            label: 'Transação',
            sortable: true,
            formatter: (value, key, item) => {
              return value === 'D' ? 'Débito' : 'Crédito';
            }
          },
          {
            key: 'mediaLength',
            label: 'Tempo',
            sortable: true,
            sortDirection: 'desc',
            formatter: (value, key, item) => {
              return new Date(value * 1000).toISOString().substr(11, 8);
            }
          },
          {
            key: 'api',
            label: 'API',
            sortable: false,
            thStyle: { width: '100px' }
          },
          {
            key: 'observation',
            label: 'Observação',
            sortable: true,
            sortDirection: 'desc',
            thStyle: { width: '300px' }
          }
        ],
        selected: 'user',
        sortDirection: 'desc',
        sortBy: 'created',
        sortDesc: true,
        totalRows: 0,
        perPage: 15,
        currentPage: 1,
        pageInfo: {},
        mounted: false,
        pageOptions: [5, 10, 15, { value: 100, text: 'Mostrar 100' }]
      };
    },
    async created () {
      await this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: this.currentPage });
      this.mounted = true;
    },
    methods: {
      getApiIconStyle (item) {
        if (item.api) {
          return this.doneIcon;
        }
      },
      onSearchClick () {
        if (this.mounted) {
          if (this.selected === 'user' && this.filterText.length > 2) {
            this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), user: this.filterText.toLowerCase() });
          } else if (this.selected === 'created') {
            this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), created: this.filterText });
          } else if (this.selected === 'observation' && this.filterText.length > 1) {
            this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), observation: this.filterText });
          } else if (this.selected === 'transactionType') {
            this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), transactionType: this.filterText.toUpperCase().startsWith('D') ? 'D' : this.filterText.toUpperCase().startsWith('C') ? 'C' : '' });
          } else if (this.selected === 'mediaLenght') {
            this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), mediaLength: this.filterText.toLowerCase() });
          } else {
            this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage) });
          }
        }
      },
      updatePage () {
        if (this.mounted) {
          this.loadExtracts({ perPage: parseInt(this.perPage), currentPage: this.currentPage });
        }
      },
      sortChanged (e) {
        if (this.mounted) {
          this.loadExtracts({
            perPage: parseInt(this.perPage),
            currentPage: this.currentPage,
            sortBy: e.sortBy,
            sortDesc: e.sortDesc
          });
        }
      },
      async loadExtracts (params) {
        this.isLoading = true;
        const result = await storeService.extract(params);
        if (result && result.length > 0) {
          this.extracts = result[0].elements;
          this.pageInfo = result[0].pageInfo;
          this.totalRows = this.pageInfo && this.pageInfo.length > 0 && this.pageInfo[0].count;
        }
        this.isLoading = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .section-content {
    display: flex;

    .search-text{
      height: 40px;
      font-weight: 700;
    }

    .overlay {
        position: absolute;
        top: 5px;
        left: 10px;

        span {
          color: #ccc;
          font-size: 18px;
        }
    }

    .list-container {
      margin-left: 20px;
      margin-right: 20px;
      flex: 1;

      .header-container {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F5F8FA;
        border-radius: 4px;

        h3 {
          margin: 0;
          font-size: 20px;
          font-weight: 400;
        }

        .search-container {
          margin-right: 4%;
        }
      }

      .table {
        margin-top: 15px;
        background-color: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 0px;
        /deep/thead th {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;
          background: #F5F8FA;
          color: #6278A3;

          border-radius: 4px 4px 0px 0px;
          padding: 0.5rem;
          padding-left: 24px;
        }

        /deep/tbody td {
          font-size: 14px;
          padding: 0.5rem;
          font-size: 14px;
          font-style: normal;
          color: #6278A3;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;
          padding-left: 24px;
        }
      }

      .new-user-button {
        width: 160;
        height: 30px;
        line-height: 100%;
        font-size: 14px;
        color: #333333;
        background-color: #bee7bd;
        border: 1px solid #7dd07b;
        text-transform: none;
        box-shadow: none;
        display: flex;
        justify-content: space-around;
        align-items: center;

          &:hover {
            background-color: #b5dcb1;
            transition: background-color 0.2s linear;
          }

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
      }

      .item-table {
        border-radius: 4px;
        background-color: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  .search-filter {
    margin-left: 3%;
  }

  .pagination-container {
    background-color: #FFFFFF;
    display: flex;
    flex-flow: row;
    align-content: center;
    alignment: center;
    padding-left: 35%;
  }

  /deep/.modal-footer .btn-primary {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
</style>
