import { render, staticRenderFns } from "./StoreExtract.vue?vue&type=template&id=23a86597&scoped=true"
import script from "./StoreExtract.vue?vue&type=script&lang=js"
export * from "./StoreExtract.vue?vue&type=script&lang=js"
import style0 from "./StoreExtract.vue?vue&type=style&index=0&id=23a86597&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a86597",
  null
  
)

export default component.exports